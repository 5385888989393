 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 944.000000 944.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,944.000000) scale(0.100000,-0.100000)">

<path d="M5734 6055 c-160 -29 -251 -66 -380 -153 -122 -82 -203 -170 -259
-282 -96 -189 -111 -404 -40 -565 81 -183 213 -300 495 -437 203 -99 274 -144
334 -208 112 -120 134 -289 59 -449 -29 -61 -110 -149 -162 -175 -146 -76
-323 -95 -521 -56 -109 21 -250 68 -316 106 l-41 24 -56 -152 c-32 -84 -57
-161 -57 -170 0 -35 157 -98 335 -135 261 -54 566 -26 777 72 287 133 454 362
488 669 24 209 -35 380 -180 527 -97 98 -160 140 -383 252 -200 102 -255 136
-317 199 -76 78 -106 168 -90 277 23 163 143 283 324 325 77 18 225 21 312 5
70 -12 204 -54 257 -81 36 -18 38 -18 44 -1 3 10 33 83 64 162 32 80 57 145
56 146 -1 0 -22 11 -47 22 -62 30 -163 59 -267 78 -106 18 -329 18 -429 0z"/>
<path d="M3560 6044 c-77 -7 -304 -35 -321 -40 -18 -5 -16 8 -54 -359 -14
-137 -37 -358 -51 -490 -25 -242 -59 -581 -134 -1305 -22 -216 -40 -404 -40
-417 l0 -23 195 0 c149 0 195 3 195 13 0 6 7 75 15 152 8 77 31 302 51 500 20
198 37 361 38 362 1 1 43 -2 93 -8 194 -21 496 24 657 98 284 131 460 348 523
644 23 113 23 301 0 389 -67 247 -250 405 -542 467 -68 14 -514 26 -625 17z
m473 -323 c96 -25 145 -50 200 -104 92 -89 131 -226 108 -379 -40 -257 -187
-417 -444 -480 -69 -16 -108 -19 -239 -16 -86 2 -160 7 -164 11 -4 4 10 172
30 374 21 203 42 415 48 473 5 58 13 109 18 113 28 31 332 36 443 8z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}